import React from 'react';
import Section from '../../../components/section';
import { PageHero } from '../../../components/page-hero';
import Layout from '../../../components/layout/layout';
import '../styles.scss';

const UseCasesItem = () => {
  return (
    <Layout>
      <PageHero
        title="Monitor pages for legal & corporate compliance"
        subtitle=""
      />
      <Section>
        <div className="use-cases-item">
          <div className="go-back">
            <a className="learn_btn_two" href="/use-cases">
              <svg width="40px" height="20px" aria-hidden="true" focusable="false" data-prefix="fas"
                data-icon="long-arrow-alt-left" className="svg-inline--fa fa-long-arrow-alt-left fa-w-14 icon"
                role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path fill="currentColor"
                  d="M134.059 296H436c6.627 0 12-5.373 12-12v-56c0-6.627-5.373-12-12-12H134.059v-46.059c0-21.382-25.851-32.09-40.971-16.971L7.029 239.029c-9.373 9.373-9.373 24.569 0 33.941l86.059 86.059c15.119 15.119 40.971 4.411 40.971-16.971V296z">
                </path>
              </svg>Back to use cases</a>
          </div>

          <div className="content-block">
            <p>Your website is the public face of your business and your corporate identity. Therefore,
            it is essential that important pages on your website are compliant with the best practices of your industry,
              government regulations, and your own corporate policies.</p>
            <p>Monitoring tools like Hexowatch can help you stay on top of any concerns related to compliance.</p>
            <h4 className="bold-title">Watch for risk words</h4>
            <p>The majority of industries have words that should never be used, or at the very least, used quite sparingly.
                Get notifications whenever these words are used on pages on your corporate website.</p>

            <h4 className="bold-title">Ensure that legalese elements are in important pages</h4>
            <p>Affiliate notices, product warnings, regulatory disclaimers, patent or copyright notices are key factors
            that can cause legal complexities if missing or misused. Use Hexowatch to monitor for the inclusion or absence of key
              text fragments and receive alerts whenever an anomaly is detected.</p>

            <h4 className="bold-title">Monitor message boards and customer forums for potential issues</h4>
            <p>Customers can potentially post insulting remarks about your company, or they can accidentally put sensitive,
            confidential information online. Monitor forums and message boards and get notifications so that you can nip these
              issues in the bud by monitoring search pages for the inclusion of your brand.</p>

            <h4 className="bold-title">Page update history and change tracking</h4>

            <p>Some industries require historical snapshots of public pages for audit reporting.
            Hexowatch can capture page snapshots when changes are detected allowing you to store an audit trail
              and be notified of any issue or mistake that may have been added to the page.</p>

            <h4>Go beyond compliance monitoring</h4>
            <p>Hexowatch is your AI sidekick to monitor any website for visual, content, source code, technology, availability or price changes.</p>
            <p>Hexowatch works 24/7 to help spot trends, spy on your competitors, visually check your website, keep an archive of every change and turn
              any website into your own private data source accessing changes as a downloadable CSV file, google sheets or via Zapier.</p>
              
          </div>

          <a href="/pricing">
            Click here to monitor any website and get started in minutes - no software, proxies, or programming required
          </a>
        </div>
      </Section>
    </Layout>
  );
};

export default UseCasesItem;
